.footer {
    position: relative;

    > .container {
        position: relative;
        padding: 45px 0;
    
        &:before {
            content: "";
            position: absolute;
            top: -50px;
            right: -335px;
            height: 50px;
            width: 1487px;
            background: url(/wp-content/themes/corsept/assets/src/images/sprite-images/home/vague_gauche.svg) no-repeat center;
            background-size: contain;
        }
    }

    &__container {
        display: flex;
    }

    &__title {
        font-family: $font-family--heading;
        font-size: $font-size--4;
        font-weight: $font-weight-bold;
        line-height: 1.5rem;
        margin: 0 0 15px;
    }

    &__wrapper-text {
        margin: 0 0 25px;
    }

    &__content {
        &--address {
            margin-right: 170px;
        }

        &--menu {
            flex: 1;
            display: flex;
            justify-content: flex-end;
        }
    }

    .button {
        margin: 0;
        padding: 0;
        line-height: 1;

        svg {
            margin-right: 12px;
        }
    }

    &__contact {
        width: 242px;
    }

    &__phone {
        width: 230px;
    }

    &__menu {
        display: flex;
        flex-direction: column;
        align-items: flex-end;

        &__link {
            position: relative;
            margin-bottom: 15px;
            color: $color-gray;
            line-height: 1.25rem;
            padding-right: 15px;
            display: flex;
            align-items: center;
    
            &:after {
                content: "";
                position: absolute;
                right: 0;
                background-color: $color-gray;
                height: 6px;
                width: 6px;
                border-radius: $border-radius--round;
            }

            &:hover {
                padding-right: 25px;
            }
        }
    }
}


// 1200
@media screen and (max-width: $large) {
    .footer {
        > .container {
            &:before {
                top: -40px;
                right: auto;
                left: 50%;
                transform: translateX(-50%);
                height: 40px;
                width: 1200px;
            }
        }

        &__content {
            &--address {
                margin-right: 90px;
            }
        }
    }
}

// 960
@media screen and (max-width: $medium) {
    .footer {
        > .container {
            &:before {
                top: -32px;
                height: 32px;
                width: 960px;
            }
        }

        &__container {
            flex-wrap: wrap;
        }

        &__content {
            &--address,
            &--hours {
                display: flex;
                flex-direction: column;
                justify-content: space-between;
            }

            &--address {
                width: 50%;
                margin-right: 0;
            }

            &--hours {
                width: 50%;
            }

            &--menu {
                margin-top: 60px;
                justify-content: flex-start;
            }
        }

        &__menu {
            max-height: 130px;
            flex-wrap: wrap;
            gap: 0 35px;
            align-items: flex-start;

            &__link {
                max-width: 285px;
                padding-right: 0;
                padding-left: 15px;

                &:after {
                    left: 0;
                    right: auto;
                }

                &:hover {
                    padding-left: 15px;
                    padding-right: 0;
                }
            }
        }
    }
}

// 640
@media screen and (max-width: $small) {
    .footer {
        > .container {
            &:before {
                top: -20px;
                height: 20px;
                width: 640px;
            }
        }

        &__content {
            &--address {
                width: 100%;
                margin-bottom: 30px;
            }

            &--hours {
                width: 100%;
            }

            &--menu {
                margin-top: 50px;
            }
        }

        &__menu {
            max-height: unset;
        }
    }
}