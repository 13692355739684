.post_navigation {
    // display: flex;
    // justify-content: center;
    padding: 0px 0 80px;
    margin-top: -50px;
    background: $color-gradient;

    .return {
        a {
            display: flex;
            align-items: center;
            font-family: $font-family--heading;
            font-size: $font-size;
            font-weight: $font-weight-semibold;
            color: $color-dark;
            height: 60px;
            border: 1px solid rgba(255,255,255,.5);
            background-color: transparent;
            border-radius: $border-radius;
            padding: 0 30px;
            transition: all $duration;

            svg {
                width: 26px;
                height: 26px;
                margin-right: 5px;
                fill: $color-dark;
                transition: all $duration;
            }

            &:hover {
                color: $color-dark;
                background-color: rgba(255,255,255,.5);

                svg {
                    fill: $color-dark;
                }
            }
        }
    }
}

// .navigation_link svg {
//     position: relative;
//     top: 5px;
// }

// Update Upgrades 11/2023
// for all buttons navigations
.navigation_link {
    column-gap: 5px;
}

.post_navigation {
    &--container {
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        justify-content: space-between;
        // Si les liens sont cachés, mettre ce style :
        // justify-content: space-evenly;
        width: 100%;
        gap: 30px 15px;
    }

    .return {
        a {
            align-items: center;
        }
    }

    .prev, .next {
      // A adapter en fonction de la width du bouton précédent
      min-width: 170px;

        .navigation_link {
            color: $color-dark;

            // Recup style ~ ligne 9
            a {
                display: flex;
                align-items: center;
                font-family: $font-family--heading;
                font-size: $font-size;
                font-weight: $font-weight-semibold;
                color: $color-dark;
                height: 60px;
                border: 1px solid rgba(255,255,255,.5);
                background-color: transparent;
                border-radius: $border-radius;
                padding: 0 30px;
                transition: all $duration;
            }
        }
    }

    .prev {
        .navigation_link {
          display: flex;
          justify-content: flex-start;

            a {
                &:before {
                    content: "";
                    display: inline-block;
                    width: 26px;
                    height: 26px;
                    background-color: $color-dark;
                    mask-image: url( $urlShort + '/ic-nav-arrow-left.svg');
                    mask-repeat: no-repeat;
                    transition: background-color 0.25s;
                    margin-right: 5px;
                }
            }
        }
    }

    .next {
        .navigation_link {
             display: flex;
            justify-content: flex-end;

          a {
                &:after {
                    content: "";
                    display: inline-block;
                    width: 26px;
                    height: 26px;
                    background-color: $color-dark;
                    mask-image: url( $urlShort + '/ic-nav-arrow-right.svg');
                    mask-repeat: no-repeat;
                    transition: background-color 0.25s;
                    margin-left: 5px;
                }
            }
        }
    }

    .prev, .next {
        &:hover, &:focus, &:focus-within {

            .navigation_link {
                color: $color-dark;

                svg {
                    fill: $color-dark;
                }

                a {
                    color: $color-dark;
                    background-color: rgba(255,255,255,.5);

                    &:before, &:after {
                        background-color: $color-dark;
                    }
                }
            }
        }
    }
}


//======================================================================================================
// Responsive
//======================================================================================================

// 640
@media screen and (max-width: $small) {

    .post_navigation {
        .return {
            display: flex;
            justify-content: center;
            order: 1;
            flex: 1 1 100%;

            a {
                padding: 0 20px;
            }
        }

        .next, .prev {
            min-width: inherit;

            .navigation_link {
                a {
                    padding: 0 20px;
                }
            }
        }
    }
}


//======================================================================================================
// Responsive 
//======================================================================================================
// 1200
@media screen and (max-width: $large) {
    .post_navigation {
        padding: 0 0 70px;
    }
}

// 960
@media screen and (max-width: $medium) {
    .post_navigation {
        padding: 0 0 60px;
    }
}

// 640
@media screen and (max-width: $small) {
    .post_navigation {
        padding: 0 0 40px;
    }
}