//======================================================================================================
// Breakpoints
//======================================================================================================
$large                                  : 1200px;
$medium                                 : 960px;
$small                                  : 640px;
$x-small                                : 320px;


//======================================================================================================
// Grids
//======================================================================================================
$gl-colCount                            : 12;
$gl-gridName                            : grid;
$gl-colName                             : col;
$gl-attributeName                       : class;
$gl-gutter                              : 30px;
$gl-gutter-vertical                     : 30px;

//Gutter responsive medium
$gl-gutter-md                           : 20px;
$gl-gutter-md-vertical                  : 20px;

//Gutter responsive small
$gl-gutter-sm                           : 20px;
$gl-gutter-sm-vertical                  : 20px;

//Gutter responsive x-small
$gl-gutter-xs                           : 20px;
$gl-gutter-xs-vertical                  : 20px;

$gl-mq-width                            : 'max-width';
$gl-mq-list: (
        lg: $large,
        md: $medium,
        sm: $small,
        xs: $x-small,
);


//======================================================================================================
// Global Colors
//======================================================================================================

// Colors
$red                                    : #C92C2C;
$redlight                               : #FFE6E6;
$orange                                 : #E8810B;
$orangelight                            : #fae6ce;
$green                                  : #46B450;
$greenlight                             : #E2FFEF;

// Main Colors
$color-main                             : #0E8930;
$color-second                           : #05A2DD;
$color-third                            : #FECF23;
$color-fourth                           : #DEDEDE;
$color-gradient                         : linear-gradient(90deg, #82D1EE 0%, #94D0A4 100%);

// Black & White
$color-white                            : #FFFFFF;
$color-light                            : #ECECEC;
$color-gray                             : #848294;
$color-dark                             : #0C1C3A;
$color-black                            : #000000;
$color-dark--variant                    : #1B2336;

// RGBA
$color-main--rgba                       : rgba(14, 137, 48, 0.8);
$color-second--rgba                     : rgba(5, 162, 221, 0.8);
$color-third--rgba                      : rgba(254, 207, 35, 0.8);
$color-black--rgba                      : rgba(0, 0, 0, 0.9);

// Bloc infos
$color-error                            : $red;
$color-alert                            : $orange;
$color-success                          : $green;
$color-bg-error                         : $redlight;
$color-bg-alert                         : $orangelight;
$color-bg-success                       : $greenlight;
$color-flexible-alert                   : #E20000; // This color is only used on bloc-content--alert

// Text
$color-text                             : $color-dark;
$color-link                             : $color-dark;
$color-link--hover                      : $color-link;
$color-highlight                        : #FFEEA8;

// Text Card
$color--card                            : $color-text;
$color-second--card                     : $color-gray;

// Text Taxo
$color--taxo                            : $color-text;

// Background
$color-bg--neutral                      : $color-light;
$color-bg--body                         : $color-white;
$color-bg--transparent                  : transparent;
$color-bg--image                        : $color-gray;
$color-bg--icons                        : $color-white;

// Background Card
$color-bg--date-v1                      : $color-main;
$color-bg--date-v2                      : $color-main--rgba;
$color-bg--card                         : $color-white;
$color-bg-neutral--card                 : $color-bg--neutral;

// Background Taxo
$color-bg--taxo                         : $color-second;


//======================================================================================================
// Typography
//======================================================================================================

// Font Weight
$font-weight-thin                       : 200;
$font-weight-light                      : 300;
$font-weight-normal                     : 400;
$font-weight-medium                     : 500;
$font-weight-semibold                   : 600;
$font-weight-bold                       : 700;
$font-weight-extrabold                  : 800;
$font-weight-black                      : 900;

// Body
$font-size                              : 16px;
$font-style                             : normal;
$font-variant                           : normal;
$font-family                            : "Roboto", sans-serif;
$font-weight                            : $font-weight-normal;
$line-height                            : 1.2;

// Font Size
$font-size--text-medium                 : 1.250rem; // 20px
$font-size--text                        : 1.000rem; // 16px
$font-size--text-small                  : 0.875rem; // 14px
$font-size--text-xsmall                 : 0.813rem; // 13px

// Headings
$font-size--1                           : 3.125rem; // 50px
$font-size--2                           : 2.125rem; // 34px
$font-size--3                           : 1.750rem; // 28px
$font-size--4                           : 1.375rem; // 22px
$font-size--5                           : 1.125rem; // 18px
$font-size--6                           : 1.000rem; // 16px

$font-family--heading                   : "Barlow", sans-serif;
$font-weight--heading                   : $font-weight-semibold;
$line-height--heading                   : 1.2;


//======================================================================================================
// Borders
//======================================================================================================
$border-width                           : 1px;
$border-width--blockquote               : 16px;
$border-style                           : solid;
$border-color                           : $color-fourth;
$border-color-text                      : $color-text;
$border-color-white                     : $color-white;
$border-radius                          : 4px;
$border-radius--round                   : 50%;
$borders                                : $border-width $border-style $border-color;
$borders--blockquote                    : $border-width--blockquote $border-style $border-color;


//======================================================================================================
// Box-shadow
//======================================================================================================
$shadow                                 : 0px 0px 1px rgba(27, 35, 54, 0.08), 1px 1px 10px rgba(27, 35, 54, 0.08);
//$shadow--hover                          : 1px 1px 5px rgba(0,0,0,.25), 0px 0px 0px rgba(0,0,0,.1);
$shadow--hover                          : 0px 0px 2px #6E727C;


//======================================================================================================
// Transitions
//======================================================================================================
$duration                               : .25s;


//======================================================================================================
// Buttons
//======================================================================================================

// Font
$btn-font-family                        : $font-family--heading;
$btn-font-weight                        : $font-weight-semibold;
$btn-font-size                          : $font-size--text;
$btn-line-height                        : 1.2;
$btn-text-transform                     : normal;

// Colors
$btn-color                              : $color-dark;
$btn-color--hover                       : $color-white;

// Backgrounds
$btn-bg                                 : $color-bg--transparent;
$btn-bg--hover                          : $color-main;

// Borders
$btn-border-width                       : 1.5px;
$btn-border-style                       : solid;
$btn-border-radius                      : 4px;
$btn-border-color                       : $color-main;
$btn-border-color--hover                : $btn-bg--hover;

// Padding
$btn-padding                            : 15px 30px;


    //===============================================
    // Button secondary
    //===============================================

    // Colors
    $btn-second-color                   : $color-dark;
    $btn-second-color--hover            : $color-dark;

    // Backgrounds
    $btn-second-bg                      : $color-bg--transparent;
    $btn-second-bg--hover               : $color-second;

    // Borders
    $btn-second-border-color            : $color-second;
    $btn-second-border-color--hover     : $btn-second-border-color;

    // Padding
    $btn-second-padding                 : 12px 50px;


    //===============================================
    // Button variant 1 (used on privacy-policy.twig)
    //===============================================

    // Colors
    $btn-variant-color                  : $color-dark;
    $btn-variant-color--hover           : $color-dark;

    // Backgrounds
    $btn-variant-bg                     : $color-white;
    $btn-variant-bg--hover              : $color-third;

    // Borders
    $btn-variant-border-color           : $btn-variant-bg--hover;
    $btn-variant-border-color--hover    : $btn-variant-bg--hover;


    //===============================================
    // Button category (on card.scss)
    //===============================================
    $btn-tax-border-radius              : $border-radius;


    //======================================================================================================
    // Buttons specific
    //======================================================================================================

    // Font
    $btn-spe-font-weight                : $font-weight-medium;
    $btn-spe-font-size                  : $font-size--text-small;
    $btn-spe-line-height                : 1.5;

    // Padding
    $btn-spe-padding                    : 7px 15px;

    // Borders
    $btn-spe-border-radius              : $border-radius;


    //===============================================
    // Button filter
    //===============================================

    // Colors
    $btn-filter-color                   : $color-dark;
    $btn-filter-color--hover            : $color-white;

    // Backgrounds
    $btn-filter-bg                      : $color-third;
    $btn-filter-bg--hover               : $color-dark;

    // Borders
    $btn-filter-border-color            : $btn-filter-bg;
    $btn-filter-border-color--hover     : $btn-filter-bg--hover;


    //===============================================
    // Button toggler
    //===============================================

    // Colors
    $btn-toggler-color--hover           : $color-dark;

    // Backgrounds
    $btn-toggler-bg--hover              : $color-white;

    // Borders
    $btn-toggler-border-color           : $color-dark;
    $btn-toggler-border-color--hover    : $color-dark;


//======================================================================================================
// Forms
//======================================================================================================
$forms                                  : ('[type=color], [type=date], [type=datetime], [type=datetime-local], [type=email], [type=month], [type=number], [type=password], [type=search], [type=tel], [type=text], [type=url], [type=week], [type=time], select, textarea');
$btn                                    : ('.button, button, [type=submit], [type=reset], [type=button]');
$input-bg                               : $color-bg--transparent;
$placeholder                            : darken($color-main, 20%);
$form-border                            : 1px solid $border-color;


//======================================================================================================
// Tables
//======================================================================================================
$stripes                                : #f8f8f8;
$caption                                : #ababab;

// Flexible table
$table-bg                               : $color-white;
$table-color                            : $color-text;
$table-border-width                     : 1px;
$table-border-color                     : $color-dark;
$table-even-bg                          : $color-white; // Pair background-color
$table-even-color                       : $color-text; // Pair color
$table-odd-bg                           : $color-light; // Impair background-color
$table-odd-color                        : $color-text; // Impair color
$table-head-bg                          : $color-dark;
$table-head-color                       : $color-white;
$table-head-border-color                : $color-white;


//======================================================================================================
// Code
//======================================================================================================
$code-color                             : $color-text;
$code-size                              : $font-size--text-small;
$code-family                            : Menlo, monospace;
$code-bg                                : $color-bg--transparent;


//======================================================================================================
// Card
//======================================================================================================
$card--padding                          : 0;
$card-content--padding                  : 20px;

$urlShort : '/wp-content/themes/corsept/assets/src/images/sprite-images/';