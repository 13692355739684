.cover {
    position: relative;

    &__content {
        position: relative;
        display: flex;
        background: $color-gradient;
    }

    > .container {
        position: relative;
    }

    &__shape {
        position: absolute;
        top: -50px;
        left: -230px;
        height: 50px;
        width: 1487px;
        background: url(/wp-content/themes/corsept/assets/src/images/sprite-images/home/vague_droite.svg) no-repeat center;
        background-size: contain;
    }

    &__image-wrapper {
        width: 50%;
        background: $color-bg--image;

        img {
            width: 100%;
            height: 100%;
            object-fit: cover;
            object-position: top;
        }
    }

    &__informations {
        min-height: 450px;
        width: calc(50% - (100% - 1170px) / 2);
        display: flex;
        flex-direction: column;
        justify-content: center;
        padding: 20px 10px 70px 50px;
    }

    &__title {
        margin: 0 0 25px;
    }

    &__intro {
        font-size: $font-size--5;
    }
}


//======================================================================================================
// Responsive
//======================================================================================================

// 1200
@media screen and (max-width: $large) {
    .cover {
        &__shape {
            top: -40px;
            width: 1189px;
            height: 40px;
            left: -120px;
        }
    }
}


// 960
@media screen and (max-width: $medium) {

    .cover {
        &__image-wrapper {
            height: 380px;
            width: 100%;
        }

        &__shape {
            top: -32px;
            width: 960px;
            height: 32px;
            left: 50%;
            transform: translateX(-50%);
        }

        &__content {
            flex-direction: column;
        }

        &__informations {
            min-height: 0;
            width: 100%;
            max-width: 640px;
            margin: auto;
            padding: 45px 0 75px;
        }
    }

}


// 640
@media screen and (max-width: $small) {

    .cover {
        &__lead-wrapper {
            p {
                font-weight: $font-weight;
            }
        }
        
        &__image-wrapper {
            height: 255px;
        }

        &__shape {
            top: -20px;
            width: 640px;
            height: 20px;
        }

        &__informations {
            max-width: 300px;
            padding: 45px 0 65px;
        }
    }

}
