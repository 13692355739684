.breadcrumb {
    position: relative;
    background-color: $color-white;

    ul  {
        display: flex;
        flex-wrap: wrap;
        min-height: 45px;
    }

    li {
        display: flex;
        align-items: center;
        color: $color-gray;
        padding: 10px 0;

        a {
            &:hover {
                text-decoration: underline;
            }
        }

        > svg {
            margin: 0 2px;
            fill: $color-gray;
            width: 26px;
            height: 26px;
        }
    }
}


//======================================================================================================
// Responsive
//======================================================================================================

// 640
@media screen and (max-width: $small) {

    .breadcrumb {
        position: relative;

        ul {
            min-height: 40px;
        }

        li {
            min-height: 40px;
        } 
    }

}
