.front-page {
    h2 {
        position: relative;
        font-size: $font-size--1 ;
        font-weight: $font-weight-medium;
        margin: 0 0 50px;
        z-index: 1;

        &:after {
            content: '';
            position: absolute;
            display: block;
            background-size: contain;
            background-repeat: no-repeat;
            background-position: center;
            bottom: -20px;
            left: -10px;
            width: 207px;
            height: 34px;
            z-index: -1;
        }
    }
}

//======================================================================================================
// SlideShow
//======================================================================================================
.home-slideshow {
    position: relative;

    &__nav {
        &-prev,
        &-next {
            top: auto;
            background-color: $color-white;
            border-radius: $border-radius--round;
            height: 40px;
            width: 40px;
            bottom: 40px;
            transition: $duration;

            svg {
                fill: $color-dark;
            }

            &::after {
                display: none;
            }

            &:hover {
                background-color: $color-second;
            }
        }

        &-prev {
            left: 15px;
        }

        &-next {
            left: 75px;
        }
    }

    .slideshow {
        height: 500px;
        width: 100%;

        img, video {
            position: absolute;
            object-fit: cover;
            width: 100%;
            height: 100%;
            object-position: top;
        }

        &__container {
            display: inherit;
            position: relative;
            width: 100%;
            height: 100%;

            .container {
                width: 100%;
                height: 100%;
                margin: auto;
                position: absolute;
                left: 50%;
                transform: translateX(-50%);
            }
        }

        &__content {
            position: absolute;
            bottom: 95px;
        }

        &__title {
            text-shadow: 1px 1px 10px $color-dark--variant;
            color: $color-white;
            font-family: $font-family--heading;
            font-weight: $font-weight-bold;
            font-size: $font-size--1;
        }

        &--video {
            img {
                display: none;
            }
        }
    }
}

//======================================================================================================
// Accès rapides
//======================================================================================================
.home-access {
    position: relative;
    background: $color-gradient;

    > .container {
        padding: 50px 0 95px;
        position: relative;

        &::before {
            content: '';
            position: absolute;
            bottom: 0;
            left: -230px;
            height: 50px;
            width: 1487px;
            background: url(/wp-content/themes/corsept/assets/src/images/sprite-images/home/vague_droite.svg) no-repeat center;
            background-size: contain;
        }
    }

    &__list {
        display: flex;
        justify-content: space-between;
    }

    &__link {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: center;
        width: 170px;
        height: 170px;
        border-radius: $border-radius;
        border: 1.5px solid $color-white;
        box-shadow: $shadow;
        padding: 15px 15px 25px;

        > svg {
            width: 60px;
            height: 16px;
            fill: $color-dark;
            margin-top: 10px;
        }

        &:hover {
            background: rgba(255,255,255,.5);
            border: 1.5px solid rgba(255,255,255,.5);
        }
    }

    &__label {
        font-family: $font-family--heading;
        font-weight: $font-weight-semibold;
        text-align: center;
        max-width: 120px;
    }

    &__svg-container {
        width: 60px;
        height: 60px;

        svg {
            width: 60px;
            height: 60px;
            fill: $color-dark;
        }
    }
}

//======================================================================================================
// Actualités + Agenda
//======================================================================================================
.home-posts-events {
    padding: 60px 0 80px;

    > .container {
        display: flex;
        justify-content: space-between;
    }
}

.home-posts, 
.home-events {
    &__header {
        margin-bottom: 10px;
    }

    &__container {
        .swiper-container {
            padding: 10px;
            margin: -10px;
        }
    }

    &__navigation {
        display: flex;
        justify-content: space-between;
        margin: 15px 0 0 25px;
    }

    &__btn-next,
    &__btn-prev {
        border-radius: $border-radius--round;
        border: 1.5px solid;
        width: 40px;
        height: 40px;
        position: relative;
        left: auto;
        right: auto;
        margin: 0 10px;

        &:hover {
            background-color: $color-white;
        }

        svg {
            width: 40px;
            height: 40px;
        }

        &::after {
            display: none;
        }
    }

    &__header {
        position: relative;
        display: flex;
        align-items: flex-start;
    }

    &__template-btn {
        display: flex;
        margin-top: 30px;
    }

    &__btn {
        svg {
            margin-right: 5px;
        }
    }
}


//======================================================================================================
// Actualités
//======================================================================================================
.home-posts {
    &__title {
        &:after {
            background-image: url(/wp-content/themes/corsept/assets/src/images/sprite-images/home/virgule_home_actus.svg);
        }
    }

    &__btn-prev,
    &__btn-next {
        background-color: $color-main;
        border-color: $color-main;

        svg {
            fill: $color-white;
        }

        &:hover {
            svg {
                fill: $color-dark;
            }
        }
    }

    &__container {
        max-width: 770px;
    }

    .featured-post {
        box-shadow: $shadow;
        transition: $duration;

        &:hover {
            box-shadow: none;

            .featured-post {
                &__image-container {
                    img {
                        transform: scale(1.1);
                    }
                }

                &__content {
                    border-color: $color-main;
                }
            }
        }

        &__image-container {
            width: 100%;
            height: 180px;
            overflow: hidden;
            background: url(/wp-content/themes/noyau/assets/images/icon-post.png) $color-gray center no-repeat;

            img {
                width: 100%;
                height: 100%;
                object-fit: cover;
                object-position: top;
                transform: scale(1);
                transition: .45s;
            }
        }

        &__content {
            padding: 25px 20px 30px;
            background-color: $color-white;
            height: 165px;
            box-sizing: border-box;
            color: $color-text;
            box-sizing: border-box;
            transition: $duration;
            border: 1.5px solid $color-white;
        }

        &__title {
            font-weight: $font-weight-bold;
            font-size: $font-size--4;
            line-height: 1.5rem;
            color: $color-main;
            margin: 0 0 5px;
            transition: $duration;
        }

        &__introduction {
            font-size: $font-size--text-small;
        }
    }

    &__btn {
        margin: 0;
        width: 370px;
    }
}

//======================================================================================================
// Agenda
//======================================================================================================
.home-events {
    width: 370px;    

    &__title {
        &:after {
            background-image: url(/wp-content/themes/corsept/assets/src/images/sprite-images/home/virgule_home_agenda.svg);
        }
    }

    &__btn-prev,
    &__btn-next {
        background-color: $color-third;
        border-color: $color-third;

        svg {
            fill: $color-dark;
        }
    }

    .featured-event {
        &:hover {
            box-shadow: none;
            
            .featured-event {
                &__content {
                    border-color: $color-third;
                }
            }
        }

        &__link {
            box-shadow: $shadow;
            display: flex;
            flex-direction: column;
        }

        &__content {
            height: 165px;
            display: flex;
            padding: 25px 20px;
            box-sizing: border-box;
            border: 1.5px solid $color-white;
        }

        &__title {
            font-weight: $font-weight-semibold;
            font-size: $font-size--3;
            line-height:1.875rem;
            color: $color-text;
            margin: 0;
            transition: $duration;
        }

        &__date {
            position: relative;
            display: flex;
            align-items: center;
            background: $color-third;
            color: $color-dark;
            font-family: $font-family--heading;
            font-weight: $font-weight-bold;
            height: 180px;
            width: 100%;
            padding: 0 20px;
            overflow: hidden;
            box-sizing: border-box;
            transition: .45s;

            &:after {
                content: '';
                position: absolute;
                left: -20px;
                top: 10px;
                width: 155px;
                height: 175px;
                background: url(/wp-content/themes/corsept/assets/src/images/sprite-images/home/calendar.svg) no-repeat center;
            }

            &-wrapper {
                display: flex;
                align-items: flex-end;

                > svg {
                    width: 32px;
                    height: 34px;
                    fill: $color-dark;
                    margin: 0 15px;
                }
            }

            p {
                display: flex;
                flex-direction: column;
                justify-content: center;
                transition: $duration;
                z-index: 1;
            }

            &__day {
                font-family: $font-family--heading;
                font-weight: $font-weight-bold;
                font-size: $font-size--1;
                line-height: 3.125rem;
                display: block;
            }

            &__month {
                font-family: $font-family--heading;
                font-weight: $font-weight-medium;
                font-size: 1.5625rem;
                line-height: 1.875rem;
                text-transform: uppercase;
                display: block;
            }
        }
    }

    &__template-btn {
        display: flex;
        flex-direction: column-reverse;
        align-items: center;
    }

    &__btn {
        margin: 0 auto 20px;
        width: 100%;
        padding: 0;

        &:first-child {
            margin: 0 auto;
        }
    }
}

//======================================================================================================
// Kiosque + Connect
//======================================================================================================
.home-docs-connect {
    padding: 80px 0 110px;
    background: $color-gradient;

    > .container {
        display: flex;
        justify-content: space-between;
    }
}

//======================================================================================================
// Kiosque 
//======================================================================================================
.home-documents {
    &__container {
        display: flex;
        margin-bottom: 20px;
    }

    &__title {
        &:after {
            background-image: url(/wp-content/themes/corsept/assets/src/images/sprite-images/home/virgule_home.svg);
        }
    }

    &__content {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        max-width: 260px;
    }

    &__container-image {
        width: 270px;
        height: 392px;
        overflow: hidden;
        flex: none;
        box-shadow: $shadow;
        background: url(/wp-content/themes/noyau/assets/images/icon-documents.png) no-repeat center $color-gray;
        margin-right: 30px;

        img {
            width: 100%;
            height: 100%;
            object-fit: cover;
            object-position: top;
        }
    }

    &__doc-title {
        font-weight: $font-weight-bold;
        font-size: $font-size--4;
        line-height: 1.375rem;
        margin: 10px 0 10px;
    }

    &__date,
    &__filesize {
        font-size: $font-size--text-small;
    }

    &__filesize {
        span {
            text-transform: uppercase;
        }
    }

    &__buttons {
        display: flex;
        margin-top: 10px;
        margin-bottom: 60px;
    }

    &__btn {
        display: flex;
        align-content: center;
        justify-content: center;
        margin-right: 20px;
        border-radius: $border-radius;
        background: $color-white;
        border: 1px solid $color-white;
        height: 40px;
        width: 40px;

        svg {
            fill: $color-dark;
        }

        &:hover {
            background-color: transparent;
        }
    }

    &__template-btn {
        margin: 0;
        padding: 0;
        border-color: $color-white;
        width: 260px;

        svg {
            display: flex;
            align-content: center;
            justify-content: center;
            fill: $color-dark;
            margin-right: 5px;
        }

        &:hover {
            background-color: $color-white;
            border-color: $color-white;
            color: $color-dark;

            svg {
                fill: $color-dark;

                path {
                    fill: $color-dark;
                }
            }
        }
    }
}

//======================================================================================================
// Connect
//======================================================================================================
.home-connect {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 470px;

    &__title {
        &:after {
            background-image: url(/wp-content/themes/corsept/assets/src/images/sprite-images/home/virgule_home.svg);
        }
    }
    
    &__link {
        display: flex;
        align-items: center;
        border-radius: $border-radius;
        width: 100%;
        height: 130px;
        border-radius: $border-radius;
        box-shadow: $shadow;
        padding: 0 50px 0 0;
        margin-bottom: 20px;

        &:hover {
            background: rgba(255,255,255,1);
        }

        &--nl {
            border: none;
            background: rgba(255,255,255,.5);

            svg {
                fill: transparent;
                stroke: $color-dark;
            }
        }

        &--fb {
            border: 1.5px solid $color-white;

            svg {
                fill: $color-dark;
            }
        }
    }

    &__svg-container {
        svg {
            width: 100px;
            height: 100px;
            margin: 0 12px;
        }
    }

    &__content {
        &__title {
            margin: 0 0 5px;
        }
    }
}

//======================================================================================================
// Responsive
//======================================================================================================

// 1200
@media screen and (max-width: $large) {
    .front-page {
        h2 {
            position: relative;
            font-size: 2.5rem;
            line-height: 3.4375rem;
            margin: 0 0 30px;

            &:after {
                bottom: -10px;
                left: 0;
                width: 150px;
                height: 25px;
            }
        }
    }

    .home-slideshow {
        &__nav-next,
        &__nav-prev {
            bottom: 30px;
        }

        &__nav-next {
            left: 70px;
        }

        .slideshow {
            height: 400px;

            &__content {
                bottom: 90px;
            }

            &__title {
                font-size: 2.5rem;
                line-height: 2.8125rem;
            }
        }
    }

    .home-access {
        > .container {
            padding: 40px 0 90px;

            &:before {
                width: 1189px;
                height: 40px;
                left: -120px;
            }
        }
    
        &__link {
            width: 140px;

            > svg {
                width: 40px;
                height: 11px;
            }
        }
    }

    .home-posts-events {
        padding: 50px 0 80px
    }

    .home-posts,
    .home-events {
        &__btn-next, 
        &__btn-prev {
            margin: 0 0 0 20px;
        }

    }

    .home-posts {
        &__navigation {
            margin: 15px 0 0 15px;
        }

        &__container {
            max-width: 620px;
        }

        &__btn {
            width: 300px;
        }
    }

    .home-events {
        width: 300px;

        &__header {
            justify-content: space-between;
        }

        .featured-event {
            &__date {
                &:after {
                    left: -15px;
                }

                &__month {
                    font-size: 1.25rem;
                }
            }
        }
    }

    .home-docs-connect {
        padding: 80px 0 100px;
    }

    .home-documents {
        &__container-image {
            width: 220px;
            height: 320px;
            margin-right: 20px;
        }

        &__buttons {
            margin-bottom: 10px;
        }
    }

    .home-connect {
        width: 380px;
        
        &__svg-container {
            svg {
                width: 77px;
                height: 77px;
                margin: 0 10px;
            }
        }
    
        &__link {
            height: 90px;
            padding: 0 20px 0 0;
        }

        &__content {
            &__text {
                display: none;
            }
        }
    }

}


// 960
@media screen and (max-width: $medium) {
    .home-slideshow {
        .slideshow {
            height: 330px;

            &--video {
                video {
                    display: none;
                }
                img {
                    display: block;
                }
            }
        }
    }

    .home-access {
        > .container {
            padding: 40px 0 70px;

            &:before {
                width: 960px;
                height: 32px;
                left: 50%;
                transform: translateX(-50%);
            }
        }

        &__list {
            flex-wrap: wrap;
        }
    
        &__link {
            width: 190px;
            margin-bottom: 20px;
        }
    }

    .home-events,
    .home-posts {
        &__btn-prev,
        &__btn-next {
            margin: 0 0 0 15px;
        }
    }

    .home-posts {
        &__header {
            justify-content: space-between;
        }

        &__navigation {
            margin: 15px 0 0 0;
        }

        &__container {
            width: 300px;
        }

        &__btn {
            width: 100%;
        }
    }

    .home-docs-connect {
        padding: 55px 0 90px;

        >.container {
            flex-direction: column;
        }
    }

    .home-connect {
        width: 100%;
        margin-top: 50px;

        &__title {
            text-align: center;

            &:after {
                left: 50%!important;
                transform: translateX(-50%);
            }
        }

        &__link {
            justify-content: center;
        }
    }
}


// 640
@media screen and (max-width: $small) {
    .front-page {
        h2 {
            line-height: 2.8125rem;
        }
    }

    .home-slideshow {
        .slideshow {
            height: 350px;
        }
    }

    .home-access {
        > .container {
            padding: 40px 0 50px;

            &:before {
                width: 640px;
                height: 20px;
            }
        }
    
        &__link {
            width: 140px;
        }
    }

    .home-posts-events {
        > .container {
            flex-direction: column;
        }
    }

    .home-events {
        margin-top: 60px;
    }

    .home-documents {
        &__title {
            &:after {
                left: 50%!important;
                transform: translateX(-50%);
            }
        }

        &__container {
            flex-direction: column;
            align-content: center;
        }

        &__container-image {
            margin: 0 auto 20px;
        }

        &__content {
            text-align: center;
            max-width: 100%;
        }

        &__doc-title {
            margin: 0 0 10px;
        }

        &__template-btn {
            width: 100%;
        }

        &__buttons {
            justify-content: center;
            margin-bottom: 20px;
        }

        &__btn {
            margin: 0 10px;
        }
    }

    .home-connect {
        margin-top: 15px;
    }
}
