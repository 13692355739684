#nav-main {
    position: relative;
    width: 100%;

    .container--nav {
        display: flex;
        justify-content: space-between;
        align-items: center;
    }

    .menu__nav__list {
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        justify-content: space-between;
        padding-left: 270px;
        padding-right: 140px;
        height: 90px;
        transition: $duration ease-out;
    } 

    .nav-main-item {

        &.menu-item-has-children:hover, &.menu-item-has-children:focus-within {
            .sub-menu {
                display: flex;
                justify-content: center;
            }
        }

        &.active {
            .nav-main-link {
                color: $color-dark;
    
                &:before {
                    height: 100%;
                }
    
                &:nth-last-child(2) {
                    &:after {
                        background: $color-dark;
                    }
                }
            }
        }

        &:nth-last-child(2) {
            .nav-main-link {
                &:after {
                    content: '';
                    position: absolute;
                    bottom: 15px;
                    left: 50%;
                    height: 5px;
                    width: calc(100% - 30px);
                    background: $color-third;
                    border-radius: 2px;
                    transform: translateX(-50%);
                    transition: .35s;
                }

                &:hover {
                    &:after {
                        background: $color-dark;
                    }
                }
            }
        }

        .nav-main-link {
            position: relative;
            display: flex;
            align-items: center;
            flex-direction: column;
            justify-content: center;
            padding: 0 15px;
            height: 90px;
            font-family: $font-family--heading;
            color: $color-dark;
            font-weight: $font-weight-bold;
            font-size: $font-size--text-medium;
            line-height: 1.25rem;
            text-transform: uppercase;

            span {
                font-weight: $font-weight-light;
            }

            &:before {
                content: '';
                position: absolute;
                bottom: 0;
                left: 50%;
                height: 0;
                width: 100%;
                background: $color-third;
                transform: translateX(-50%);
                z-index: -1;
                transition: .35s;
            }

            &:hover {
                color: $color-dark;

                &:before {
                    height: 100%;
                }
            }
        }

        .sub-menu {
            display: none;
            position: absolute;
            left: 0;
            top: 90px;
            width: 100%;
            padding: 30px 0;
            background: rgba(12, 28, 58, .95);
            box-shadow: $shadow;

            &:before,
            &:after {
                content: "";
                width: 2000px;
                height: 100%;
                top: 0;
                position: absolute;
                background: rgba(12, 28, 58, .95);
            }

            &:before {
                left: -2000px;
            }

            &:after {
                right: -2000px;
            }

            ul.nav-drop,
            ul.nav-child {
                width: 100%;
                column-count: 3;
                column-gap: 30px;
            }

            .nav-child-item, .nav-grandchild-item {
                display: inline-block;
                width: 100%;
                margin: 0 0 10px;
                padding: 0;
                line-height: initial;
                border: 1px solid rgba(255, 255, 255, 0.3);
                border-radius: $border-radius;

                a {
                    position: relative;
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                    width: 100%;
                    min-height: 50px;
                    padding: 5px 7px 5px 10px;
                    color: $color-white;
                    font-family: $font-family--heading;
                    font-weight: $font-weight-semibold;
                    font-size: $font-size--5;
                    border-radius: $border-radius;
                    background-color: transparent;
                    line-height: 1;

                    &::after {
                        content: '\f345';
                        font: normal 20px/1 dashicons;
                        display: inline-block;
                        margin-left: 10px;
                        color: $color-white;
                        opacity: .5;
                        transition: all $duration;
                    }

                    &:hover {
                        background-color: $color-third;
                        color: $color-dark;

                        &::after {
                            color: $color-dark;
                            opacity: 1;
                        }
                    }
                }
            }

            .nav-grandchild {
                margin: 10px;
                border-radius: $border-radius;
                border: 1px solid rgba(255, 255, 255, 0.3);
            }

            .nav-grandchild-item {
                padding: 0;
                margin: 0;
                border: none;
                border-radius: 0;
                border-bottom: 1px solid rgba(255, 255, 255, 0.3);

                a {
                    display: flex;
                    padding: 5px 20px 5px 10px;
                    border-bottom: 0;
                    font-size: $font-size;
                    line-height: initial;

                    &:after {
                        right: 16px;
                    }
                } 

                &:last-child {
                    border-bottom: none;
                }
            }
        }
    }
}

// Responsive button
.nav-main_button {
    display: none;
}

// Responsive button label
.nav-main_icon {
    display: none;
    align-items: center;
    cursor: pointer;
    padding: 10px 20px;
    color: $color-dark;
    font-family: $font-family--heading;

    .navicon {
        position: relative;
        display: block;
        height: 3.5px;
        top: 1px;
        width: 18px;
        margin-right: 8px;
        background: $color-dark;
        border-radius: 2px;
        transition: all $duration ease-out;

        &:before, 
        &:after {
            content: '';
            display: block;
            position: absolute;
            width: 100%;
            height: 100%;
            background: $color-dark;
            transition: all $duration ease-out;
        }

        &:before {
            top: 7px;
        }

        &:after {
            top: -7px;
        } 
    }
}


//=================================================================================================
// Responsive
//=================================================================================================

// 1200
@media screen and (max-width: $large) {

    #nav-main {
        .menu__nav__list {
            padding-left: 170px;
            padding-right: 125px;
        }

        .nav-main-item {
            &:nth-last-child(2) {
                .nav-main-link {
                    &:after {
                        width: calc(100% - 10px);
                    }
                }
            }

            .nav-main-link {
                padding: 0 5px;
            }
        }
    }

}


// 960
@media screen and (max-width: $medium) {

    #nav-main {
        position: relative;

        .container--nav {
            position: relative;
        }

        .menu__nav__list {
            display: flex;
            flex-flow: column;
            position: absolute;
            top: 90px;
            width: 100%;
            margin: auto;
            padding: 0;
            background: rgba(12, 28, 58, .95);
            box-shadow: $shadow;

            &:before,
            &:after {
                content: "";
                width: 2000px;
                height: 100%;
                top: 0;
                position: absolute;
                background: rgba(12, 28, 58, .95);
            }

            &:before {
                left: -2000px;
            }

            &:after {
                right: -2000px;
            }
        }

        .nav-main-item {
            width: 100%;

            .nav-main-link {
                position: relative;
                display: flex;
                align-items: center;
                width: 100%;
                max-width: 100%;
                height: 60px;
                padding: 0 20px;
                border-bottom: 1px solid $color-white;
                color: $color-white;
                text-transform: uppercase;

                &:first-child {
                    padding: 0 20px;
                }

                &:after {
                    display: none;
                }

                &:hover {
                    background-color: $color-third;
                }
            }

            &:last-child {
                .nav-main-link {
                    border-bottom: 0;

                    &:after {
                        width: 0;
                        height: 0;
                        background: $color-bg--transparent;
                    }
                }
            }

            &.menu-item-has-children:hover, &.menu-item-has-children:focus-within {
                .sub-menu {
                    display: none;
                }
            }

            &.active,
            &.menu-item-has-children.active {
                .nav-main-link {
                    color: $color-dark;
                    background-color: $color-third;
                }

                .sub-menu {
                    display: flex;
                    position: relative;
                    padding: 5px 10px;
                    box-shadow: none;
                    background: none;
                    top: initial;

                    &:before,
                    &:after {
                        display: none;
                    }   

                    .nav-drop {
                        column-count: 1;
                    }

                    .nav-grandchild-item {
                        a {
                            padding: 10px;
                        }
                    }

                    li:last-child {
                        a {
                            border-bottom: 0;

                            &::before {
                                display: none;
                            }

                            &::after {
                                top: 50%;
                                transform: translate(0,-50%);
                            }
                        }
                    }
                }
            }

        }

        // Responsive button
        .nav-main_button {
            ~ .menu {
                clear: both;
                transform: scaleY(0);
                transform-origin: 50% 0;
                transition:transform .3s ease;
            }

            &:checked {
                ~ .menu {
                    transform: scaleY(1);
                    height: auto;
                }
                ~ .nav-main_icon {
                    .navicon {
                        background: $color-bg--transparent;

                        &:before {
                            transform: rotate(-45deg);
                        }

                        &:after {
                            transform: rotate(45deg);
                        }
                    }

                    &:not(.steps) {
                        .navicon {
                            &:before,
                            &:after {
                                top: 0;
                            }
                        }
                    }
                }
            }
        }

        // Responsive button label
        .nav-main_icon {
            display: flex;
            width: 100%;
            height: 90px;
            margin: 0;
            padding: 0 125px 0 200px;
            font-size: 1.5rem;
            font-weight: 700;
            text-transform: uppercase;
            justify-content: flex-end;
        }
    }
    
}

// 640
@media screen and (max-width: $small) {
    #nav-main {
        width: 100%;
        padding-right: calc(50% + 15px);

        .nav-main_icon {
            padding: 0;
            height: 85px;
        }

        .menu__nav__list {
            top: 85px;
        }
    }
}